export default [
  {
    path: '/mainCertificate/:MCR_id/:Certificate_Name',
    name: 'certificate',
    component: () => import('@/views/main-certificate/certificate/Certificate.vue'),
    meta: {
      title: 'Certificate ',
      requiresAuth: false,
      action: 'browes',
      resource: 'certificate',
      pageTitle: 'Certificate',
      breadcrumb: [
        {
          text: 'Main Certificate',
          to: '/mainCertificate',
        },
        {
          text: 'Certificate',
          to: '/mainCertificate/:MCR_id/:Certificate_Name',
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  // {
  //   path: "/mainCertificate/add/:MCR_id",
  //   name: "add-certificate",
  //   component: () =>
  //     import(
  //       "@/views/main-certificate/certificate/control-certificate/AddEditCertificate.vue"
  //     ),
  //   meta: {
  //     title: "Add Certificate ",
  //     requiresAuth: false,
  //     action: "create",
  //     resource: "certificate",
  //     pageTitle: "Certificate",
  //     breadcrumb: [
  //       {
  //         text: "Certificate",
  //         to: "/mainCertificate"
  //       },
  //       {
  //         text: "Add",
  //         active: true
  //       }
  //     ]
  //   }
  // },
  {
    path: '/mainCertificate/:MCR_id/:Certificate_Name/edit/:id/',
    name: 'edit-certificate',
    component: () => import(
      '@/views/main-certificate/certificate/control-certificate/AddEditCertificate.vue'
    ),
    meta: {
      title: 'Edit Certificate ',
      requiresAuth: false,
      pageTitle: 'Certificate',
      breadcrumb: [
        {
          text: 'Main Certificate',
          to: '/mainCertificate',
        },
        {
          text: 'Certificate',
          to: '/mainCertificate/:MCR_id/:Certificate_Name',
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },
]
